<template>
  <v-row class="my-0">
    <v-col xl="8" lg="8" md="12" sm="12" class="py-0">
      <v-card
        flat
        class="rounded-20 ma-0 mx-4 mt-4 d-flex flex-column"
        height="65vh"
      >
        <v-card-title class="title-font">
          CHOOSE A PRODUCT TO ORDER
        </v-card-title>
        <v-row>
          <v-col cols="6" align="center" class="my-10">
            <DesignOptions @chooseOrder="$emit('chooseOrder', 'DESIGN')" />
          </v-col>

          <v-col cols="6" align="center" class="my-10">
            <BarOptions @chooseOrder="$emit('chooseOrder', 'BAR')" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      xl="4"
      lg="4"
      md="12"
      sm="12"
      v-if="orderForwardedOrders?.length"
      class="py-0"
    >
      <ExistingAndPartialOrders />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import ExistingAndPartialOrders from "./ChooseOrder/ExistingAndPartialOrders.vue"
import DesignOptions from "./ChooseOrder/DesignOptions.vue"
import BarOptions from "./ChooseOrder/BarOptions.vue"

export default {
  name: "ChooseOrder",

  components: {
    ExistingAndPartialOrders,
    DesignOptions,
    BarOptions
  },

  data() {
    return {
      partialOrders: []
    }
  },

  computed: {
    ...mapGetters(["orderForwardedOrders"])
  }
}
</script>
