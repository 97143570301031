<template>
  <div>
    <v-card flat class="rounded-20">
      <v-card-title class="title-font">
        UPLOAD &amp; VALIDATE SCANS
      </v-card-title>
      <v-card-text align="center" v-if="initialising">
        <LoadingSpinner />
      </v-card-text>
      <v-card-text v-if="!initialising">
        <v-form ref="uploadScansForm">
          <ScanGaugeKitSelector v-model="kit_box_id" />
          <NewOrderCaseType
            v-model="isImmediate"
            v-if="settings.immediate_workflow"
          />
          <NewOrderScanUploadAndValidate
            :loading="loading"
            :sessionId="ncSessionId"
            :leftToRight="leftToRightScan"
            :rightToLeft="rightToLeftScan"
            :validateScansRes="validateScansResponse"
            @update="updateInstance"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          x-large
          class="mt-0 btn-success rounded-10"
          @click="submitScans"
          :loading="loading"
          :disabled="loading || initialising || !scanGaugeKits?.length"
        >
          Save Scans
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="submitting"
      width="350px"
      persistent
      content-class="rounded-20"
    >
      <v-card class="pb-4">
        <v-card-title class="off">
          <h2 class="title-h2 primary--text">PROCESSING</h2>
        </v-card-title>
        <v-card-text class="text-center py-0">
          <LoadingSpinner class="mt-4" />
        </v-card-text>
        <v-card-text class="title-font pb-0 mt-2">File Uploads:</v-card-text>
        <v-card class="rounded-20 mx-4 py-2" flat outlined>
          <v-card-text class="title-font py-2"
            >Scan Gauge Scans:<v-progress-linear
              :color="scanGaugeUploadProgress === 100 ? 'success' : 'primary'"
              :value="scanGaugeUploadProgress"
            ></v-progress-linear
          ></v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import ScanGaugeKitSelector from "@/components/InboxPage/NewOrder/ScanGaugeKitSelector.vue"
import NewOrderCaseType from "@/components/InboxPage/NewOrder/NewOrderCaseType.vue"
import NewOrderScanUploadAndValidate from "@/components/InboxPage/NewOrder/NewOrderScanUploadAndValidate.vue"

export default {
  name: "UploadScans",

  components: {
    ScanGaugeKitSelector,
    NewOrderCaseType,
    NewOrderScanUploadAndValidate
  },

  data() {
    return {
      initialising: true,
      loading: false,
      submitting: false,
      kit_box_id: null,
      ncSessionId: null,
      leftToRightScan: null,
      rightToLeftScan: null,
      validateScansResponse: null,
      isImmediate: false,
      failedUploads: [],
      scanGaugeUploadProgress: 0
    }
  },

  watch: {
    settings(val, prevVal) {
      if (val && this.clients && prevVal === null) {
        this.initialising = false
      }
    }
  },

  computed: {
    ...mapGetters([
      "settings",
      "selectedOrder",
      "selectedOrderSession",
      "scanGaugeKits"
    ])
  },

  methods: {
    ...mapActions(["triggerReload"]),

    updateInstance(key, value) {
      this[key] = value
    },

    async submitScans() {
      const scoreKey = this.immediate ? "scoreImmediate" : "scoreRestorative"
      if (!this.$refs.uploadScansForm.validate()) return
      if (this.validateScansResponse?.scanAnalysis[scoreKey] < 4) return
      this.submitting = true
      this.loading = true
      await client.instance.orders
        .associateSession(
          this.selectedOrder.uid,
          this.selectedOrderSession.uid,
          {
            nc_session_id: this.ncSessionId,
            kit_box_id: this.kit_box_id
          }
        )
        .then(async () => {
          await this.uploadFiles(
            this.selectedOrder.uid,
            this.selectedOrderSession.uid,
            [this.leftToRightScan, this.rightToLeftScan]
          )
        })
        .catch(() => {
          this.submitting = false
          this.loading = false
          return console.error("Upload Failed")
        })
      this.triggerReload("order")
      this.triggerReload("files")
      this.submitting = false
      this.loading = false
    },

    async uploadFiles(orderUid, orderSessionUid, files) {
      if (!files.length) return

      const uploadedFiles = await client.oft.uploadFiles(files, (progress) => {
        this.scanGaugeUploadProgress = progress
      })

      let payload = []

      if (uploadedFiles) {
        uploadedFiles.forEach((file) => {
          if (file.uploaded) {
            payload.push({
              file_name: file.oftFileName,
              file_type: "scan",
              oft_file_uid: file.oftUid,
              file_category: "scan_gauge_scan"
            })
          } else this.failedUploads.push(file)
        })
      }

      await client.instance.orders.addFiles(orderUid, orderSessionUid, payload)
    }
  },

  async mounted() {
    this.initialising = true
    if (this.settings) this.initialising = false
    else {
      this.triggerReload("settings")
    }
    if (this.scanGaugeKits.length === 1)
      this.kit_box_id = this.scanGaugeKits[0].kit_box_id
  }
}
</script>
