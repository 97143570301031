<template>
  <v-card flat class="rounded-20">
    <v-card-title class="title-font"> SCAN GAUGE ALIGNMENT </v-card-title>

    <v-card-text class="text-center mx-auto" style="width: 100%; height: 400px">
      <LoadingSpinner v-if="loading" />
      <div ref="view"></div>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col class="ml-10">
          <v-switch
            :disabled="loading"
            inset
            dense
            label="Scan Gauge Libraries"
            v-model="showLibraries"
          />
          <v-switch
            :disabled="loading"
            inset
            dense
            label="Constructed Libraries"
            v-model="showConstructedLibraries"
          />
        </v-col>
        <v-col
          :class="{ 'mr-10': true, 'ml-10': $vuetify.breakpoint.lgAndDown }"
        >
          <v-switch
            :disabled="loading"
            inset
            dense
            label="Scan Gauges"
            v-model="showScanGauges"
          />
          <v-switch
            :disabled="loading"
            inset
            dense
            label="Tissue Scan"
            v-model="showScanTissue"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="text-center">
      <v-btn
        small
        text
        class="btn-primary"
        @click="triggerRedo"
        @loading="redoLoading"
        :disabled="redoLoading || loading || selectedOrderExports?.length > 0"
      >
        <v-icon class="mr-2">mdi-restore</v-icon>
        REDO LIBRARY ASSOCIATION
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import apiClient from "@/lib/ApiClient"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AssociationPreview",

  view: null,

  props: ["redoLoading"],

  data() {
    return {
      loading: false,
      showScanGauges: true,
      showScanTissue: true,
      showLibraries: true,
      showConstructedLibraries: true
    }
  },

  watch: {
    showScanGauges(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setScanGaugesVisible(value)
      }
    },
    showScanTissue(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setScanTissueVisible(value)
      }
    },
    showLibraries(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setLibrariesVisible(value)
      }
    },
    showConstructedLibraries(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setConstructedLibrariesVisible(value)
      }
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrderSession",
      "cachedNcSession",
      "selectedOrderExports"
    ])
  },

  methods: {
    ...mapActions(["setCachedNcSession"]),

    triggerRedo() {
      this.$emit("redoAnalysis")
    },

    async getNcSession() {
      this.loading = true
      let sessionResponse

      if (this.selectedOrderSession.nc_session_id === this.cachedNcSession?.id)
        sessionResponse = this.cachedNcSession.response
      else {
        sessionResponse = await NCFrontend.ApiClient.sessionGet(
          `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
          this.$axios.defaults.headers.Authorization,
          this.selectedOrderSession.nc_session_id
        )
        this.setCachedNcSession({
          id: this.selectedOrderSession.nc_session_id,
          response: sessionResponse
        })
      }

      if (!this.$refs.view) return

      this.view = NCFrontend.createView(
        "libraryAssociationView",
        this.$refs.view
      )
      NCFrontend.add(this.view)
      this.view.setVisualisation(
        new NCFrontend.Visualisations.LibraryAssociation(this.view.element, {
          fixedScan: sessionResponse.fixedScan,
          scanAnalysis: sessionResponse.scanAnalysis,
          immediateCase: this.selectedOrderSession.session_type === "immediate"
        })
      )

      setTimeout(() => {
        this.view.visualisation.setLibraryAssociationData(
          sessionResponse.libraryAssociation
        )
        this.showScanGauges = false
        this.showScanTissue = true
        this.showLibraries = false
        this.showConstructedLibraries = true
        this.loading = false
      }, 0)
    }
  },

  mounted() {
    this.getNcSession()
  }
}
</script>
