<template>
  <v-row>
    <v-col xl="6" lg="8" md="12" sm="12" class="pa-0">
      <ExportAbutment
        class="mt-1"
        @exported="onExport"
        @cancelled="$emit('cancelled')"
      />
    </v-col>
    <v-col xl="6" lg="4" md="12" sm="12" class="pa-0">
      <v-card
        flat
        :class="{
          'rounded-20': true,
          'mt-4': true,
          'ml-4': $vuetify.breakpoint.lgAndUp
        }"
      >
        <v-card-text>
          <ExistingExports v-model="newExportUid" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ExportAbutment from "./ExportComponent/ExportAbutment.vue"
import ExistingExports from "./ExportComponent/ExistingExports.vue"

export default {
  name: "ExportComponent",

  data() {
    return {
      historyExports: [],
      newExportUid: null,
      loading: false
    }
  },

  components: {
    ExportAbutment,
    ExistingExports
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedOrderExports"])
  },

  methods: {
    ...mapActions(["triggerReload"]),

    onExport(newExportUid) {
      this.newExportUid = newExportUid
    }
  },

  mounted() {
    this.triggerReload("exports")
  }
}
</script>
