<template>
  <v-card flat>
    <v-card-title>
      <v-icon class="mr-2">mdi-account-group</v-icon>
      Client Management
    </v-card-title>
    <v-card-text>
      <v-row align="center" class="text-subtitle-2 px-2">
        <v-icon class="mr-2"></v-icon>
        <span class="success--text" v-if="manageClients">
          Manage Clients Enabled
        </span>
        <span class="error--text" v-if="!manageClients">
          Manage Clients Disabled
        </span>
        <v-spacer />
        <v-switch color="success" v-model="manageClients" inset />
      </v-row>
      <v-row class="px-5 mt-5">
        <div>
          Allowing Nexus Connect to store details about your clients unlocks the
          full potential of this platform including:
          <ul>
            <li>Case chat to and from your clients</li>
            <li>Case proposal / approval capabilities</li>
            <li>
              Client overview of all cases submitted to you, along with Rx forms
              and files submitted
            </li>
            <li>Automated email notifications to your clients</li>
          </ul>
        </div>
        <div class="mt-5">
          <strong>
            This information is stored securely and is not visible to anyone
            outside of your organisation.
          </strong>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ManageClientsSelector",

  props: ["value"],

  computed: {
    manageClients: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
