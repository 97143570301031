<template>
  <v-card flat class="rounded-10">
    <v-form ref="serviceInformation" lazy-validation>
      <DataStorageSelector v-model="form.instance_uid" />
      <DomainSelector v-model="form.slug" />
      <ManageClientsSelector v-model="form.manage_clients" />
      <ImmediateWorkflowSelector v-model="form.immediate_workflow" />
    </v-form>
    <v-card-text class="d-flex">
      <v-btn class="btn-primary-light rounded-10" @click="previous()" text>
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="btn-primary rounded-10" @click="next()" text>
        Continue
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import DataStorageSelector from "./ServiceInformation/DataStorageSelector.vue"
import DomainSelector from "./ServiceInformation/DomainSelector.vue"
import ManageClientsSelector from "./ServiceInformation/ManageClientsSelector.vue"
import ImmediateWorkflowSelector from "./ServiceInformation/ImmediateWorkflowSelector.vue"

export default {
  name: "ServiceInformation",

  props: ["value"],

  components: {
    DataStorageSelector,
    DomainSelector,
    ManageClientsSelector,
    ImmediateWorkflowSelector
  },

  computed: {
    form() {
      return this.value
    }
  },

  methods: {
    next() {
      if (this.$refs.serviceInformation.validate()) {
        this.$emit("next")
      }
    },

    previous() {
      this.$emit("previous")
    }
  }
}
</script>
