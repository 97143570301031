<template>
  <v-card flat>
    <v-card-title class="px-0 pb-0">
      <v-icon color="error" class="mr-2" v-if="!valid">mdi-close-circle</v-icon>
      Upload Scans
    </v-card-title>

    <v-card-text v-if="retrying" class="mt-5">
      <v-alert color="warning" dark dense>
        <v-icon class="mr-2">mdi-alert-circle</v-icon>
        Your previous scan validation failed.
      </v-alert>
      <p class="ml-5">
        Please supply an additional set of scans to retry validation.
      </p>
      <v-data-table
        v-if="retrying"
        :headers="[
          { text: 'Attempt #', value: 'attempt' },
          { text: 'Scan 1', value: 'scan1' },
          { text: 'Scan 2', value: 'scan2' }
        ]"
        :items="uploadedScans"
        disable-sort
        hide-default-footer
      />
    </v-card-text>

    <v-card-text
      v-if="uploadedScans.length === 3 && validationSuccess === false"
      class="mt-5"
    >
      <v-alert color="error" dark dense>
        <v-icon class="mr-2">mdi-alert-circle</v-icon>
        Maximum retry count reached - you will need to restart this order with
        new gauge scans.
      </v-alert>
    </v-card-text>

    <v-row>
      <v-col cols="6" class="px-0">
        <NewOrderScanUploader
          v-model="leftToRightScan"
          :disabled="!!bypass"
          categoryTitle="Left to Right"
        />
        <v-chip
          v-if="leftToRightScan"
          small
          close
          class="rounded-10 mx-4"
          :disabled="!!bypass"
          @click:close="leftToRightScan = null"
        >
          <v-icon small>mdi-paperclip</v-icon>
          <span class="text-truncate">
            {{ leftToRightScan.name }}
          </span>
        </v-chip>
      </v-col>
      <v-col cols="6" class="px-0">
        <NewOrderScanUploader
          v-model="rightToLeftScan"
          :disabled="!!bypass"
          categoryTitle="Right to Left"
        />
        <v-chip
          v-if="rightToLeftScan"
          small
          close
          class="rounded-10 mx-4"
          :disabled="!!bypass"
          @click:close="rightToLeftScan = null"
        >
          <v-icon small>mdi-paperclip</v-icon>
          <span class="text-truncate">
            {{ rightToLeftScan.name }}
          </span>
        </v-chip>
      </v-col>
    </v-row>

    <v-card-title class="px-0">
      <v-icon
        v-if="!valid && (!validateScanResponse || !validationSuccess)"
        color="error"
        class="mr-2"
        >mdi-close-circle</v-icon
      >
      Validate Scans
    </v-card-title>

    <v-card-text class="px-0">
      <span v-if="!validateScansResponse" :class="valid ? null : 'error--text'">
        Scans have not been validated.
      </span>
      <template v-if="validateScansResponse">
        <span v-if="!validationSuccess" :class="valid ? null : 'error--text'">
          <v-icon color="error" class="mr-1">mdi-close-circle</v-icon>
          Scans have failed validation.
        </span>
        <span v-if="validationSuccess">
          <v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
          Scans have been successfully validated.
        </span>
      </template>
    </v-card-text>

    <div class="d-flex">
      <v-btn
        class="btn-primary"
        @click="toggleValidateScans"
        :disabled="
          !this.leftToRightScan || !this.rightToLeftScan || loading || ncLoading
        "
      >
        <span v-if="!bypass"> Validate Scans </span>
        <span v-if="bypass"> Scan Validation </span>
      </v-btn>
      <v-btn
        v-if="validationSuccess === false && uploadedScans.length < 3"
        class="ml-3 elevation-0 rounded-10"
        @click="setRetrying(true)"
        :disabled="loading || ncLoading"
      >
        Retry
      </v-btn>
      <v-btn
        v-if="validationSuccess === false && uploadedScans.length === 3"
        class="ml-3 elevation-0 rounded-10"
        @click="restart"
        :disabled="loading || ncLoading"
      >
        Restart
      </v-btn>
      <v-scale-transition origin="center center">
        <div class="flex-grow-1 ml-3 mr-1" v-if="ncLoading">
          <v-progress-linear
            rounded
            indeterminate
            color="primary"
            height="36"
            class="rounded-10"
            background-opacity="0.6"
          >
            <span
              class="text-body-2 font-weight-medium white--text loading-pulse"
            >
              PROCESSING...
            </span>
          </v-progress-linear>
        </div>
      </v-scale-transition>
    </div>

    <v-dialog
      v-model="validateScansDialog"
      width="70vw"
      no-click-animation
      :persistent="!validationSuccess"
    >
      <NewOrderScanValidator
        v-model="validateScansResponse"
        :validateScansDialog="validateScansDialog"
        :retrying="retrying"
        :retryCount="retryCount"
        :ncSessionId="ncSessionId"
        :addScansResponse="addScansResponse"
        :uploadedScans="uploadedScans"
        :validationSuccess="validationSuccess"
        :immediate="immediate"
        @close="validateScansDialog = false"
        @restart="restart"
        @error="setError"
        @retrying="setRetrying"
      />
      <ErrorAlert v-model="errorMessage" v-if="validateScansDialog" />
    </v-dialog>
    <ErrorAlert v-model="errorMessage" v-if="!validateScansDialog" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import apiClient from "@/lib/ApiClient"
import ErrorAlert from "./NewOrderScanUploadAndValidate/ErrorAlert.vue"
import NewOrderScanUploader from "./NewOrderScanUploadAndValidate/NewOrderScanUploader.vue"
import NewOrderScanValidator from "./NewOrderScanUploadAndValidate/NewOrderScanValidator.vue"

export default {
  name: "NewOrderScanUploadAndValidate",

  props: [
    "leftToRight",
    "rightToLeft",
    "validateScansRes",
    "sessionId",
    "immediate",
    "loading"
  ],

  components: {
    NewOrderScanUploader,
    NewOrderScanValidator,
    ErrorAlert
  },

  inject: ["form"],

  data() {
    return {
      ncLoading: false,
      retrying: false,
      validateScansDialog: false,
      retryCount: 0,
      uploadedScans: [],
      addScansResponse: null,

      valid: true,
      errorMessage: null
    }
  },

  created() {
    this.form.register(this)
  },

  computed: {
    ...mapGetters(["token"]),

    ncSessionId: {
      get() {
        return this.sessionId
      },
      set(value) {
        this.$emit("update", "ncSessionId", value)
      }
    },

    leftToRightScan: {
      get() {
        return this.leftToRight
      },
      set(value) {
        this.$emit("update", "leftToRightScan", value)
      }
    },

    rightToLeftScan: {
      get() {
        return this.rightToLeft
      },
      set(value) {
        this.$emit("update", "rightToLeftScan", value)
      }
    },

    validateScansResponse: {
      get() {
        return this.validateScansRes
      },
      set(value) {
        this.$emit("update", "validateScansResponse", value)
      }
    },

    validationSuccess() {
      if (!this.validateScansResponse) return
      const scoreKey = this.immediate ? "scoreImmediate" : "scoreRestorative"
      return this.validateScansResponse.scanAnalysis[scoreKey] >= 4
    },

    bypass() {
      return (
        (!this.retrying && this.uploadedScans.length) ||
        this.uploadedScans.length === this.retryCount + 1
      )
    }
  },

  methods: {
    async toggleValidateScans() {
      if (!this.leftToRightScan || !this.rightToLeftScan) return
      this.ncLoading = true
      if (!this.ncSessionId) {
        // Error handling?
        try {
          const response = await NCFrontend.ApiClient.sessionCreate(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            this.$axios.defaults.headers.Authorization
          )
          this.ncSessionId = response.sessionId
        } catch (err) {
          this.errorMessage = "An internal error occurred."
          console.error(err)
        }
      }

      this.$nextTick(async () => {
        if (
          !this.uploadedScans.length ||
          (this.retrying && this.uploadedScans.length === this.retryCount)
        ) {
          try {
            this.addScansResponse = await NCFrontend.ApiClient.sessionAddScans(
              `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
              this.$axios.defaults.headers.Authorization,
              {
                sessionId: this.ncSessionId,
                scan1File: this.leftToRightScan,
                scan2File: this.rightToLeftScan,
                retrying: this.retrying
              }
            )
            this.uploadedScans.push({
              attempt: this.uploadedScans.length + 1,
              scan1: this.leftToRightScan.name,
              scan2: this.rightToLeftScan.name
            })
          } catch (err) {
            if (err instanceof NCFrontend.ApiClient.ErrorResponse) {
              switch (err.type) {
                case NCFrontend.ApiClient.ErrorType.IdenticalScanFiles:
                  this.errorMessage = "Identical scans detected."
                  break
                case NCFrontend.ApiClient.ErrorType.ResourceNotFound:
                  this.errorMessage = "Failed to find session."
                  break
                case NCFrontend.ApiClient.ErrorType.MaxValidationRetriesReached:
                  this.errorMessage = "Max retry attempts reached."
                  break
                case NCFrontend.ApiClient.ErrorType
                  .ScansAlreadyUsedInValidation:
                  this.leftToRightScan = null
                  this.rightToLeftScan = null
                  this.errorMessage =
                    "Scans have already been used in an attempt."
                  return (this.ncLoading = false)
                case NCFrontend.ApiClient.ErrorType.ServerError:
                  this.errorMessage = "An internal server error occurred."
                  break
              }
            } else this.errorMessage = "An internal error occurred."
            console.error(err)
            this.leftToRightScan = null
            this.rightToLeftScan = null
          }
        }

        this.ncLoading = false

        this.validateScansDialog = !this.validateScansDialog
      })
    },

    setRetrying(bool) {
      this.retrying = bool
      if (this.retrying) {
        this.retryCount++
        this.validateScansDialog = false
        this.validateScansResponse = null
        this.leftToRightScan = null
        this.rightToLeftScan = null
      }
    },

    restart() {
      this.ncSessionId = null
      this.leftToRightScan = null
      this.rightToLeftScan = null
      this.addScansResponse = null
      this.uploadedScans = []
      this.retrying = false
      this.validateScansResponse = null
      this.validateScansDialog = false
      this.retryCount = 0
      this.errorMessage = null
    },

    setError(message) {
      this.errorMessage = message
    },

    validate() {
      this.valid = !!this.validationSuccess
      return this.valid
    }
  }
}
</script>
