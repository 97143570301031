<template>
  <v-card flat>
    <v-card-title class="title-font pt-0 pl-0">
      EXISTING EXPORT FILES
      <v-progress-circular class="ml-5" v-if="loading" indeterminate />
    </v-card-title>
    <v-card-text v-if="!selectedOrderExports?.length && !loading">
      There are no Existing Export Files.
    </v-card-text>
    <ExportCard
      v-for="exportItem in selectedOrderExports"
      :key="exportItem.uid"
      :export-item="exportItem"
      :new-export="exportItem.uid === newExportUid"
      class="mt-2"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ExportCard from "./ExistingExports/ExportCard.vue"
import { delay } from "@/utils"

export default {
  name: "ExistingExports",

  props: ["value"],

  components: {
    ExportCard
  },

  data() {
    return {
      timeout: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters(["exportComponents", "selectedOrderExports"]),

    newExportUid: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },

  watch: {
    async newExportUid(val) {
      if (val) {
        if (!this.selectedOrderExports?.find((ex) => ex.uid === val)) {
          await this.pollExport()
        }
        this.timeout = setTimeout(() => (this.newExportUid = null), 15000)
      }
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    async pollExport() {
      if (
        !this.selectedOrderExports?.find((ex) => ex.uid === this.newExportUid)
      ) {
        this.loading = true
        await delay(1000)

        this.triggerReload("exports")
        return this.pollExport()
      }
      this.loading = false
    }
  },

  unmounted() {
    clearTimeout(this.timeout)
  }
}
</script>
