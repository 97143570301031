<template>
  <v-container fluid fill-height class="pt-0">
    <v-row class="fill-height">
      <v-col xl="2" lg="3" md="4" class="py-0">
        <v-card flat class="card-bg rounded-20">
          <v-card-text>
            <v-list dark dense class="transparent pt-0">
              <v-list-item :class="orderButtonColor" :to="orderButtonLocation">
                <v-list-item-icon class="mr-4">
                  <v-icon> {{ orderButtonIcon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-font font-weight-medium">
                    {{ orderButtonText }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <InboxActions />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xl="10" lg="9" md="8" class="py-0 pl-0">
        <InboxItems
          :inboxItems="actionedFilter"
          :loading="loading"
          :filter="filter"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="welcome" width="600px" content-class="rounded-20">
      <v-card @click="welcome = false" class="pa-3">
        <v-card-title>
          <h2 class="title-h2 primary--text">Congratulations!</h2>
        </v-card-title>
        <v-card-text>
          <strong>
            Welcome to Nexus Connect Lab, Your Subscription is Now Ready!
          </strong>
        </v-card-text>
        <v-responsive class="mx-5 mb-3">
          <iframe
            width="100%"
            height="310"
            src="https://www.youtube.com/embed/yWKhXTYWVYY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="rounded-10"
          ></iframe>
        </v-responsive>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import InboxActions from "./InboxPage/InboxActions"
import InboxItems from "./InboxPage/InboxItems"

export default {
  name: "InboxPage",

  props: ["filter", "uid"],

  components: {
    InboxActions,
    InboxItems
  },

  data() {
    return {
      welcome: this.$route.params.welcome || false,
      loading: false,
      selectedCase: null
    }
  },

  watch: {
    inboxItems(val) {
      if (val) {
        this.loading = false
        if (this.$route.meta.caseView && !this.selectedOrder) {
          const selectedOrder = this.inboxItems.find(
            (item) => item.uid === this.$route.params.uid
          )
          if (selectedOrder) this.setSelectedOrder(selectedOrder)
        }
      }
    },

    newOrder(currentValue, previousValue) {
      if (!currentValue && previousValue) this.triggerReload("inboxItems")
    },

    $route(value) {
      if (value.name !== "caseView") {
        this.setNewOrder(false)
        return
      }
      if (value.params && value.params.uid === "new") this.setNewOrder(true)
    }
  },

  computed: {
    ...mapGetters(["inboxItems", "scanGaugeKits", "gaugeKitSalesOrders"]),

    orderButtonColor() {
      if (!this.scanGaugeKits || !this.gaugeKitSalesOrders) return "warning"
      if (
        this.scanGaugeKits.length === 0 &&
        this.gaugeKitSalesOrders.length === 0
      )
        return "error"
      if (
        this.scanGaugeKits.length === 0 &&
        this.gaugeKitSalesOrders.length > 0
      )
        return "warning"
      return "secondary"
    },

    orderButtonIcon() {
      if (!this.scanGaugeKits || !this.gaugeKitSalesOrders)
        return "mdi-clock-outline"
      if (
        this.scanGaugeKits.length === 0 &&
        this.gaugeKitSalesOrders.length === 0
      )
        return "mdi-alert-circle-outline"
      if (
        this.scanGaugeKits.length === 0 &&
        this.gaugeKitSalesOrders.length > 0
      )
        return "mdi-clock-outline"
      return "mdi-plus"
    },

    orderButtonText() {
      if (!this.scanGaugeKits || !this.gaugeKitSalesOrders) return "LOADING"
      if (
        this.scanGaugeKits.length === 0 &&
        this.gaugeKitSalesOrders.length === 0
      )
        return "ORDER A KIT"
      if (
        this.scanGaugeKits.length === 0 &&
        this.gaugeKitSalesOrders.length > 0
      )
        return "KIT ARRIVING SOON"
      return "CREATE NEW ORDER"
    },

    orderButtonLocation() {
      if (!this.scanGaugeKits || !this.gaugeKitSalesOrders)
        return { name: "scanGaugeKitsPage" }
      if (this.scanGaugeKits.length > 0) {
        return { name: "caseView", params: { uid: "new" } }
      }
      return { name: "scanGaugeKitsPage" }
    },

    effectiveFilter() {
      return this.filter || "inbox"
    },

    actionedFilter() {
      if (this.inboxItems === null) return []
      if (this.effectiveFilter === "all") return this.inboxItems
      if (this.effectiveFilter === "waiting")
        return this.inboxItems.filter((item) => item.awaiting_response)
      return this.inboxItems.filter((item) =>
        item.statuses.includes(this.effectiveFilter)
      )
    },

    newOrder() {
      return this.$route.params.uid === "new"
    }
  },

  methods: {
    ...mapActions(["triggerReload", "setNewOrder", "setSelectedOrder"]),

    async fetchOrders() {
      this.triggerReload("inboxItems")
    },

    sendConfetti() {
      this.$confetti.start()
      setTimeout(() => this.$confetti.stop(), 2000)
    }
  },

  async mounted() {
    if (this.$route.params.welcome) {
      this.sendConfetti()
      this.triggerReload("all")
    }
    if (this.inboxItems === null) this.loading = true
    this.fetchOrders()
  }
}
</script>
