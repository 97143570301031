<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent mb-3" flat dense>
      <v-toolbar-title class="title-font"> COMPANY INFO </v-toolbar-title>
    </v-toolbar>
    <v-card v-if="loading" flat class="mx-4 rounded-10 pb-4" align="center">
      <v-progress-circular indeterminate class="mt-4" color="primary" />
    </v-card>
    <v-card flat class="mx-4 rounded-10" v-if="!loading">
      <v-list v-if="!edit">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title-font"> NAME </v-list-item-title>
            <v-list-item-subtitle>
              {{ tenantInformation.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title-font">
              PHONE NUMBER
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ tenantInformation.phone_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title-font">
              EMAIL ADDRESS
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ tenantInformation.email_address }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title-font">
              BILLING ADDRESS
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ tenantInformation.billing_address_1 }}<br />
              {{ tenantInformation.billing_address_2
              }}<br v-if="tenantInformation.billing_address_2" />
              {{ tenantInformation.billing_suburb }}
              {{ tenantInformation.billing_state }}
              {{ tenantInformation.billing_postal_code }}<br />
              {{ tenantInformation.billing_country }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title-font">
              SHIPPING ADDRESS
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ tenantInformation.shipping_address_1 }}<br />
              {{ tenantInformation.shipping_address_2
              }}<br v-if="tenantInformation.shipping_address_2" />
              {{ tenantInformation.shipping_state }}
              {{ tenantInformation.shipping_postal_code }}<br />
              {{ tenantInformation.shipping_country }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card flat v-if="edit">
        <v-card-text>
          <v-form ref="businessInfoForm" lazy-validation>
            <v-text-field
              autofocus
              v-model="tenantInformation.name"
              required
              :rules="rules"
              class="mb-n1"
              outlined
              dense
              label="Business Name *"
            />
            <PhoneInput
              v-model="tenantInformation.phone_number"
              label="Phone Number *"
              :country="country"
            />
            <v-text-field
              v-model="tenantInformation.email_address"
              required
              :rules="[...rules, ...emailRule]"
              outlined
              dense
              type="email"
              label="Email *"
            />
            <BillingAddress
              v-model="tenantInformation"
              hide-same-shipping="true"
            />
            <ShippingAddress v-model="tenantInformation" />
          </v-form>
        </v-card-text>
      </v-card>
    </v-card>
    <v-card-actions class="mx-2">
      <v-spacer />
      <v-btn class="btn-primary" v-if="!edit" @click="edit = true">Edit</v-btn>
      <v-btn class="btn-error" @click="cancelEdit()" v-if="edit">Cancel</v-btn>
      <v-btn class="btn-success" v-if="edit" @click="saveChanges()">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import client from "@/lib/ApiClient"
import PhoneInput from "@/components/shared/PhoneInput.vue"
import BillingAddress from "@/components/RegistrationPage/BusinessInformation/BillingAddress.vue"
import ShippingAddress from "../RegistrationPage/BusinessInformation/ShippingAddress.vue"

export default {
  name: "TenantDetails",

  components: {
    PhoneInput,
    BillingAddress,
    ShippingAddress
  },

  data() {
    return {
      loading: true,
      originalTenantInformation: null,
      tenantInformation: null,
      edit: false,
      rules: [(v) => !!v || "Required"],
      emailRule: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) ||
          "E-mail must be valid"
      ]
    }
  },

  methods: {
    async fetchTenant() {
      this.tenantInformation =
        await client.gateway.tenants.fetchTenantInformation()
      this.originalTenantInformation = { ...this.tenantInformation }
      this.loading = false
    },

    cancelEdit() {
      this.tenantInformation = this.originalTenantInformation
      this.edit = false
    },

    async saveChanges() {
      if (!this.$refs.businessInfoForm.validate()) return
      this.loading = true
      this.tenantInformation =
        await client.gateway.tenants.updateTenantInformation(
          this.tenantInformation
        )
      this.loading = false
      this.edit = false
      window.location.reload()
    }
  },

  mounted() {
    this.fetchTenant()
  }
}
</script>
