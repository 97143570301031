<template>
  <v-card
    outlined
    flat
    :class="{ 'gradient-bg-2': newExport, 'pa-3': true, 'rounded-20': true }"
  >
    <v-row>
      <v-col xl="12" lg="12" md="12" sm="12" class="pa-2">
        <strong>
          {{ exportItem.created_at | luxon({ output: "med" }) }}
        </strong>
      </v-col>
      <v-col xl="6" lg="6" md="12" sm="12" class="pa-2">
        <strong> Scan Body: </strong>
        <br />
        {{ libraryName(exportItem.scan_body_component_id) }}
      </v-col>
      <v-col xl="6" lg="6" md="12" sm="12" class="pa-2">
        <strong v-if="exportItem.healing_cap_component_id">
          Healing Cap:
        </strong>
        <br />
        {{ libraryName(exportItem.healing_cap_component_id) }}
      </v-col>
      <v-col xl="12" lg="12" md="12" sm="12" class="text-right pa-2">
        <v-btn class="btn-primary" @click="downloadScanBody(exportItem)">
          <v-icon class="mr-2">mdi-download</v-icon>
          Scan Body
        </v-btn>
        <v-btn
          class="ml-2 btn-primary"
          @click="downloadHealingCap(exportItem)"
          v-if="exportItem.healing_cap_file"
        >
          <v-icon class="mr-2">mdi-download</v-icon>
          Healing Cap
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ExportCard",

  props: ["exportItem", "newExport"],

  computed: {
    ...mapGetters(["exportComponents"])
  },

  methods: {
    libraryName(componentId) {
      return this.exportComponents?.find((c) => c.id === parseInt(componentId))
        ?.name
    },

    downloadScanBody(exportItem) {
      const scanBodyLink = document.createElement("a")
      scanBodyLink.href = exportItem.scan_body_file.url
      scanBodyLink.setAttribute("download", "")
      scanBodyLink.style.display = "none"
      document.body.appendChild(scanBodyLink)
      scanBodyLink.click()
      setTimeout(() => document.body.removeChild(scanBodyLink), 900)
    },

    downloadHealingCap(exportItem) {
      const healingCapLink = document.createElement("a")
      healingCapLink.href = exportItem.healing_cap_file?.url
      healingCapLink.setAttribute("download", "")
      healingCapLink.style.display = "none"
      document.body.appendChild(healingCapLink)
      healingCapLink.click()
      setTimeout(() => {
        document.body.removeChild(healingCapLink)
      }, 900)
    }
  }
}
</script>
