<template>
  <div>
    <v-card flat class="mt-4 rounded-20" v-if="fullmode">
      <v-card-title class="title-font"> CASE CHAT </v-card-title>
    </v-card>

    <v-card flat :class="{ 'rounded-20': true, 'mt-4': fullmode }">
      <v-card-title class="title-font" v-if="!fullmode">
        CASE CHAT
        <v-spacer />
        <v-tooltip top class="rounded-10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('fullmode')" icon v-bind="attrs" v-on="on">
              <v-icon> mdi-arrow-expand-all </v-icon>
            </v-btn>
          </template>
          <span>Expand Chat</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="text-center ma-0 pa-0">
        <v-scale-transition>
          <v-btn
            small
            text
            v-show="newMessage"
            class="btn-primary ma-2"
            @click="readMessages()"
          >
            <v-icon small class="mr-2">mdi-message-outline</v-icon>
            New Message Received. Click to Dismiss
          </v-btn>
        </v-scale-transition>
      </v-card-text>
      <v-card-text>
        <MessageComponent
          :messages="messages"
          :approvals="approvals"
          :loading-message="loadingMessage"
          :sending-message="sendingMessage"
          @compose="sendMessage"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import MessageComponent from "./CaseChat/MessageComponent.vue"

export default {
  components: { MessageComponent },

  props: ["fullmode"],

  data() {
    return {
      loadingMessage: false,
      sendingMessage: false,
      failedUploads: []
    }
  },

  watch: {
    orderMessages(val) {
      if (val && this.orderApprovals) {
        this.loadingMessage = false
        this.sendingMessage = false
      }
    },

    orderApprovals(val) {
      if (val && this.orderMessages) {
        this.loadingMessage = false
      }
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "orderMessages",
      "orderApprovals",
      "country"
    ]),

    messages() {
      if (this.orderMessages === null) return []
      return this.orderMessages
    },

    approvals() {
      if (this.orderApprovals === null) return []
      return this.orderApprovals
    },

    newMessage() {
      if (this.selectedOrder === null) return false
      return this.selectedOrder.new_message
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    async fetchApprovals() {
      if (this.orderApprovals === null) this.loadingMessage = true
      this.triggerReload("approvals")
    },

    async fetchMessages() {
      if (this.orderMessages === null) this.loadingMessage = true
      this.triggerReload("messages")
    },

    async sendMessage(message, attachments) {
      this.sendingMessage = true

      if (attachments.length) {
        const uploadedFiles = await client.oft.uploadFiles(attachments)

        const payload = []

        uploadedFiles.forEach((file) => {
          if (file.uploaded) {
            payload.push({
              file_name: file.oftFileName,
              oft_file_uid: file.oftUid
            })
          } else this.failedUploads.push(file)
        })

        message.attachments = payload
      }

      await client.instance.messages.sendMessage(
        this.selectedOrder.uid,
        message
      )

      this.fetchMessages()
    },

    async readMessages() {
      await client.instance.messages.viewMessages(this.selectedOrder.uid)
      this.triggerReload("order")
      this.triggerReload("inboxItems")
    }
  },

  mounted() {
    this.fetchMessages()
    this.fetchApprovals()
  }
}
</script>
