<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row>
      <v-col xl="6" lg="8" md="12" sm="12" class="py-0">
        <v-card flat class="card-bg rounded-20">
          <v-toolbar class="transparent" flat dense>
            <v-toolbar-title class="title-font">
              CLIENT MANAGEMENT
            </v-toolbar-title>
          </v-toolbar>
          <v-card flat class="mx-4 rounded-10">
            <v-data-table
              :headers="headers"
              :items="users"
              hide-default-footer
              class="rounded-10"
            >
              <template v-slot:no-data v-if="loading">
                <LoadingSpinner class="ma-4" />
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editUser(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
            <ClientModal
              v-model="dialog"
              :client="editedClient"
              :form-title="formTitle"
              :loading="loading"
              @close="close"
              @save="save"
              @addIdentity="addIdentity"
              @removeIdentity="removeIdentity"
            />

            <v-dialog
              v-model="dialogDelete"
              max-width="550px"
              content-class="rounded-20"
            >
              <v-card>
                <v-card-title class="text-h5 off">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-text
                  v-if="deleteError"
                  class="d-flex align-center justify-center pa-0"
                >
                  <v-alert color="error" dark class="text-center my-5">
                    <v-icon class="mr-2"> mdi-alert </v-icon>
                    This Client has created Orders and cannot be deleted.
                  </v-alert>
                </v-card-text>
                <v-card-actions class="off">
                  <v-spacer />
                  <v-btn
                    text
                    color="error"
                    class="rounded-10"
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    class="btn-primary"
                    @click="deleteItemConfirm"
                    :disabled="deleteError"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
          <v-card-actions class="mx-2">
            <v-spacer />
            <v-btn @click="dialog = true" class="btn-primary rounded-10" text>
              Add Client
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ClientModal from "./ClientPage/ClientModal.vue"

export default {
  name: "ClientPage",

  components: { ClientModal },

  data() {
    return {
      loading: false,
      dialog: false,
      editedIndex: -1,
      dialogDelete: false,
      deleteError: false,
      countryList: [],
      editedClient: {},
      headers: [
        {
          text: "Clinic",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Contact Name",
          align: "start",
          sortable: false,
          value: "contact"
        },
        {
          text: "Email",
          sortable: false,
          value: "email"
        },
        {
          text: "Phone Number",
          sortable: false,
          value: "phone"
        },
        {
          text: "Actions",
          sortable: false,
          value: "action"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["currentTenant", "signUpSession", "clients"]),

    formTitle() {
      return this.editedIndex === -1 ? "New Client" : "Edit Client"
    },

    users() {
      if (this.clients === null) return []
      return this.clients
    }
  },

  watch: {
    clients(val) {
      if (val) this.loading = false
      if (this.editedIndex > -1)
        this.editedClient = this.users.find(
          (u) => u.uid === this.editedClient.uid
        )
    },

    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    editUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedClient = { ...item }
      this.dialog = true
    },

    deleteUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedClient = item
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      if (
        this.editedClient.role === "Owner" &&
        this.users.filter((item) => item.role === "Owner").length === 1
      ) {
        this.dialogDelete = false
        return
      }
      try {
        await client.instance.clients.deleteClient(this.editedClient.uid)
        this.users.splice(this.editedIndex, 1)
        this.closeDelete()
      } catch {
        this.deleteError = true
      }
    },

    close() {
      this.editedClient = {}
      this.editedIndex = -1
      this.$nextTick(() => {
        this.dialog = false
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.deleteError = false
      this.$nextTick(() => {
        this.editedClient = {}
        this.editedIndex = -1
      })
    },

    async save() {
      this.loading = true
      if (this.editedIndex > -1) {
        this.users[this.editedIndex] = this.editedClient
      } else {
        const form = {
          ...this.editedClient
        }
        const userResponse = await client.instance.clients.createClient(form)
        this.users.push(userResponse)
      }
      this.loading = false
      this.close()
    },

    async addIdentity(payload) {
      this.loading = true
      await client.instance.clients.addIdentity(this.editedClient.uid, payload)
      this.triggerReload("clients")
    },

    async removeIdentity(idStringUid) {
      this.loading = true
      await client.instance.clients.removeIdentity(
        this.editedClient.uid,
        idStringUid
      )
      this.triggerReload("clients")
    }
  },

  async beforeMount() {
    if (this.clients === null) this.loading = true
    this.triggerReload("clients")
  }
}
</script>
