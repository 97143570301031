<template>
  <div style="height: 48px">
    <v-toolbar
      dense
      color="warning"
      flat
      style="position: fixed; width: 100%; z-index: 9999"
    >
      <v-spacer />
      <v-toolbar-title class="white--text title-font font-weight-regular">
        Your subscription is scheduled to be cancelled on
        {{ subscription.end_time | luxon }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "AppBar",

  computed: {
    ...mapGetters(["subscription"])
  }
}
</script>
