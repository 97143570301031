<template>
  <v-card flat class="rounded-10 text-center">
    <v-sheet :color="statusColor" dark class="mb-2">
      <v-icon class="mr-2" small>{{ statusIcon }}</v-icon>
      {{ statusText }}
    </v-sheet>
    <v-img height="150" contain :src="require('@/assets/sgk-gen2.png')" />
    <v-card-title>
      <span class="mx-auto"> {{ itemTitle }} </span>
    </v-card-title>
    <v-card-text class="mb-0 pb-0">
      <small>
        {{ itemDescription }}
      </small>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        icon
        @click="expanded = !expanded"
        :disabled="item._type !== 'salesOrder' || !item.dispatched_at"
      >
        <v-icon v-show="!expanded">mdi-chevron-down</v-icon>
        <v-icon v-show="expanded">mdi-chevron-up</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card-text v-show="expanded">
        <v-row v-if="item.dispatched_at">
          <v-col>
            <strong>Dispatched:</strong>
            <br />
            {{ item.dispatched_at | luxon }}
          </v-col>
        </v-row>
        <v-row v-if="item.courier">
          <v-col>
            <strong>Courier:</strong>
            <br />
            {{ item.courier }}
          </v-col>
        </v-row>
        <v-row v-if="item.tracking_number">
          <v-col>
            <strong>Tracking Number:</strong>
            <br />
            {{ item.tracking_number }}
          </v-col>
        </v-row>
        <v-row v-if="item.tracking_link">
          <v-col>
            <strong>Tracking Link:</strong>
            <br />
            {{ item.tracking_link }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "ScanGaugeCard",

  props: ["item"],

  data() {
    return {
      expanded: false
    }
  },

  computed: {
    dispatchExpired() {
      const secondsInAWeek = 604800
      if (this.item._type !== "salesOrder") return 0
      if (!this.item.dispatched_at) return 0
      return (
        (new Date().getTime() - new Date(this.item.dispatched_at).getTime()) /
          1000 >
        secondsInAWeek
      )
    },

    statusColor() {
      if (this.item._type === "kit") return "success"
      if (!this.item.dispatched_at) return "primary"
      if (this.item.dispatched_at && !this.dispatchExpired) return "primary"
      return "success"
    },

    statusText() {
      if (this.item._type === "kit") return "Ready"
      if (this.item.dispatched_at && !this.dispatchExpired) return "Dispatched"
      if (this.item.dispatched_at && this.dispatchExpired) return "Ready"
      return "Ordered"
    },

    statusIcon() {
      if (this.item._type === "kit") return "mdi-check"
      if (this.item.dispatched_at && !this.dispatchExpired)
        return "mdi-truck-outline"
      if (this.item.dispatched_at && this.dispatchExpired) return "mdi-check"
      return "mdi-clock-outline"
    },

    itemTitle() {
      if (this.item._type === "kit")
        return this.prettyKitName(this.item.kit_box_id)
      if (this.item.kit) return this.prettyKitName(this.item.kit.kit_box_id)
      return this.item.order_key
    },

    itemDescription() {
      if (this.item._type === "kit") return this.item.kit_type
      if (this.item.dispatched_at) return this.item.kit.kit_type
      return this.item.product_required
    }
  },

  methods: {
    prettyKitName(kit_box_id) {
      const parts = kit_box_id.split("_")
      return `${parts[parts.length - 1]} (${parts[parts.length - 2]})`
    }
  }
}
</script>
