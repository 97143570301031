<template>
  <v-container fluid class="pa-0">
    <v-container fluid v-if="!selectedOrder" class="pa-4">
      <v-skeleton-loader type="heading" class="my-5" />
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-if="!initialising" class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-toolbar dark color="primary" elevation="0" height="42">
            <v-btn color="primary" elevation="0">
              <v-icon class="mr-2">mdi-inbox-outline</v-icon>
              {{ selectedOrder.order_number }}
            </v-btn>
            <SessionSwitcher color="primary" />
            <v-spacer />
            <v-btn color="primary" elevation="0">
              <v-icon class="mr-2">mdi-office-building-outline</v-icon>
              {{ selectedOrder.client_name }}
            </v-btn>
            <v-spacer />
            <v-btn color="primary" elevation="0">
              <v-icon class="mr-2">mdi-account-outline</v-icon>
              {{ selectedOrder.patient_reference }}
            </v-btn>
            <v-spacer />
            <v-btn icon @click="closeOrder" dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-toolbar flat class="mx-4" color="transparent">
            <v-tabs
              class="rounded-20 rounded-t"
              align-with-title
              v-model="tabIndex"
              background-color="white"
            >
              <v-tab @click="changeTab('overview')">Overview</v-tab>
              <v-tab @click="changeTab('library')">
                <v-icon
                  v-if="!selectedOrderSession.nc_session_id"
                  color="error"
                  small
                  class="mr-2"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-if="
                    selectedOrderSession.nc_session_id &&
                    !selectedOrderSession.associated
                  "
                  color="warning"
                  small
                  class="mr-2"
                >
                  mdi-alert-circle
                </v-icon>
                <v-icon
                  v-if="
                    selectedOrderSession.nc_session_id &&
                    selectedOrderSession.associated
                  "
                  color="success"
                  small
                  class="mr-2"
                >
                  mdi-check-circle
                </v-icon>
                Library Association
              </v-tab>
              <v-tab @click="changeTab('export')">
                <v-icon
                  v-if="!selectedOrderSession.associated"
                  color="error"
                  small
                  class="mr-2"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-if="selectedOrderExports?.length"
                  color="success"
                  small
                  class="mr-2"
                >
                  mdi-check-circle
                </v-icon>
                Export
              </v-tab>
              <v-tab @click="changeTab('order')">
                <v-icon
                  v-if="!selectedOrderSession.associated"
                  color="error"
                  small
                  class="mr-2"
                >
                  mdi-alert
                </v-icon>
                <v-img
                  contain
                  width="16"
                  class="mr-1"
                  :src="require('@/assets/osteon-logo.png')"
                />
                Order
              </v-tab>
              <v-tab @click="changeTab('order-form')">Order Form</v-tab>
              <v-tab @click="changeTab('chat')">
                <v-badge
                  bordered
                  color="primary"
                  icon="mdi-message-outline"
                  :value="selectedOrder.new_message"
                >
                  Chat &amp; Notes
                </v-badge>
              </v-tab>
              <v-tab @click="changeTab('scans-files')">Scans &amp; Files</v-tab>
            </v-tabs>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-card
        v-if="tab === 'overview'"
        flat
        height="100%"
        width="100%"
        class="transparent px-4"
      >
        <v-row>
          <v-col xl="4" lg="4" md="6" sm="12">
            <PreviewComponent class="mb-4" />
            <OrderForm @fullmode="changeTab('order-form')" class="mb-4" />
          </v-col>
          <v-col
            :xl="!!selectedOrderSession.nc_session_id ? 4 : 8"
            :lg="!!selectedOrderSession.nc_session_id ? 4 : 8"
            :md="!!selectedOrderSession.nc_session_id ? 6 : 12"
            sm="12"
          >
            <UploadScans
              class="mb-4"
              v-if="!reloadVisuals && !selectedOrderSession.nc_session_id"
            />
            <AlignmentScan
              class="mb-4"
              v-if="
                !reloadVisuals &&
                selectedOrderSession.nc_session_id &&
                !selectedOrderSession.associated
              "
              @toggleViewLibAssociation="changeTab('library')"
            />
            <AssociationPreview
              class="mb-4"
              v-if="
                !reloadVisuals &&
                selectedOrderSession.nc_session_id &&
                selectedOrderSession.associated
              "
              :redoLoading="redoLoading"
              @redoAnalysis="redoAnalysis"
            />
            <CaseChat
              v-if="$vuetify.breakpoint.mdAndDown"
              @fullmode="changeTab('chat')"
              class="mb-4"
            />
            <OpenForwardedOrders
              v-if="$vuetify.breakpoint.mdAndDown"
              class="mb-4"
              @openOrder="changeTab('order', 'forwardedOrder', ...arguments)"
            />
            <v-card
              flat
              class="rounded-20 mt-4"
              v-if="selectedOrderSession.kit_box_id"
            >
              <v-card-title class="title-font"> SCAN GAUGE KIT </v-card-title>
              <v-card-text>
                <v-card flat max-width="256" class="mx-auto">
                  <v-img
                    height="75"
                    contain
                    :src="require('@/assets/sgk-gen2.png')"
                  />
                  <h3 class="text-center mt-2">
                    {{ prettyKitName(selectedOrderSession.kit_box_id) }}
                  </h3>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="4"
            v-if="
              selectedOrderSession.nc_session_id && $vuetify.breakpoint.lgAndUp
            "
          >
            <CaseChat @fullmode="changeTab('chat')" class="mb-4" />
            <OpenForwardedOrders
              class="mb-4"
              @openOrder="changeTab('order', 'forwardedOrder', ...arguments)"
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="tab === 'library'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <LibraryAssociation
          v-if="!reloadVisuals && selectedOrderSession.nc_session_id"
        />
        <div v-if="!selectedOrderSession.nc_session_id">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font">
              SCAN GAUGE SCAN LIBRARY ASSOCIATION
            </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  <p>
                    <strong>
                      {{ toTitleCase(selectedOrderSession.session_type) }}
                      Session
                    </strong>
                  </p>
                  <p>
                    You must supply validated scans for this session before you
                    can perform a library association.
                  </p>
                </div>
                <div class="mt-10">
                  <v-btn
                    text
                    class="btn-primary"
                    @click="changeTab('overview')"
                  >
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    ULOAD SCANS
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 'export'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ExportComponent
          class="mx-4"
          v-if="
            !reloadVisuals &&
            selectedOrderSession.nc_session_id &&
            selectedOrderSession.associated
          "
        />
        <div v-if="!selectedOrderSession.nc_session_id">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> ORDER </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  <p>
                    <strong>
                      {{ toTitleCase(selectedOrderSession.session_type) }}
                      Session
                    </strong>
                  </p>
                  <p>
                    You must supply validated scans and perform a library
                    association for this session before you can export.
                  </p>
                </div>
                <div class="mt-10">
                  <v-btn
                    text
                    class="btn-primary"
                    @click="changeTab('overview')"
                  >
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    ULOAD SCANS
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div
          v-if="
            selectedOrderSession.nc_session_id &&
            !selectedOrderSession.associated
          "
        >
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> EXPORT ABUTMENTS </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must perform a library association before exporting
                  abutment positions or ordering products from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="changeTab('library')">
                    <v-icon class="mr-2">$gauge</v-icon>
                    START LIBRARY ASSOCIATION
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 'order'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <OrderComponent
          v-if="
            selectedOrderSession.nc_session_id &&
            selectedOrderSession.associated
          "
          @toExport="changeTab('export')"
        />
        <div v-if="!selectedOrderSession.nc_session_id">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> ORDER </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  <p>
                    <strong>
                      {{ toTitleCase(selectedOrderSession.session_type) }}
                      Session
                    </strong>
                  </p>
                  <p>
                    You must supply validated scans and perform a library
                    association for this session before you can order from
                    Osteon.
                  </p>
                </div>
                <div class="mt-10">
                  <v-btn
                    text
                    class="btn-primary"
                    @click="changeTab('overview')"
                  >
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    ULOAD SCANS
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div
          v-if="
            selectedOrderSession.nc_session_id &&
            !selectedOrderSession.associated
          "
        >
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> ORDER </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must perform a library association before exporting
                  abutment positions or ordering products from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="changeTab('library')">
                    <v-icon class="mr-2">$gauge</v-icon>
                    START LIBRARY ASSOCIATION
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 'order-form'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <OrderForm class="mx-4" :fullmode="true" />
      </v-card>

      <v-card
        v-if="tab === 'chat'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <CaseChat class="mx-4" :fullmode="true" />
      </v-card>

      <v-card
        v-show="tab === 'scans-files'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ScansAndFiles />
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

import SessionSwitcher from "./OrderView/SessionSwitcher.vue"
import PreviewComponent from "./OrderView/PreviewComponent.vue"
import OrderForm from "./OrderView/OrderForm.vue"
import AlignmentScan from "./OrderView/AlignmentScan.vue"
import AssociationPreview from "./OrderView/AssociationPreview.vue"
import CaseChat from "@/components/shared/CaseChat.vue"
import LibraryAssociation from "./OrderView/LibraryAssociation.vue"
import ExportComponent from "./OrderView/ExportComponent.vue"
import OrderComponent from "./OrderView/OrderComponent.vue"
import ScansAndFiles from "./OrderView/ScansAndFiles.vue"
import OpenForwardedOrders from "@/components/shared/OpenForwardedOrders.vue"
import UploadScans from "./OrderView/UploadScans.vue"
import { toTitleCase } from "@/utils"

export default {
  name: "OrderView",

  props: ["uid"],

  components: {
    SessionSwitcher,
    PreviewComponent,
    OrderForm,
    AlignmentScan,
    AssociationPreview,
    CaseChat,
    LibraryAssociation,
    ExportComponent,
    OrderComponent,
    ScansAndFiles,
    OpenForwardedOrders,
    UploadScans
  },

  data() {
    return {
      initialising: true,
      redoLoading: false,
      reloadVisuals: false,
      tab: "",
      tabs: [
        "overview",
        "library",
        "export",
        "order",
        "order-form",
        "chat",
        "scans-files",
        "order-form"
      ]
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedOrderSession",
      "orderForwardedOrders",
      "selectedForwardedOrder",
      "selectedOrderExports",
      "featureFlags"
    ]),

    tabIndex: {
      get() {
        return this.tabs.indexOf(this.tab)
      },

      set() {}
    }
  },

  watch: {
    uid(value) {
      if (!value) return
      this.$router.push({ query: { tab: "overview" } })
      this.fetchOrder()
    },

    $route(value) {
      this.tab = value.query.tab
    },

    selectedOrderSession(currentValue, oldValue) {
      if (!oldValue) return
      if (oldValue?.uid === currentValue.uid) return
      this.reloadVisuals = true
      setTimeout(() => {
        this.reloadVisuals = false
      }, 100)
    }
  },

  methods: {
    ...mapActions([
      "setSelectedOrder",
      "setCachedNcScanBodies",
      "setCachedNcSession",
      "setOrderFiles",
      "setOrderMessages",
      "setOrderForwardedOrders",
      "setSelectedForwardedOrder",
      "triggerReload"
    ]),

    toTitleCase,

    prettyKitName(kit_box_id) {
      const parts = kit_box_id.split("_")
      return `${parts[parts.length - 1]} (${parts[parts.length - 2]})`
    },

    redoAnalysis() {
      this.redoLoading = true
      client.instance.orders.redoAnalysis(this.uid).then(async () => {
        await this.fetchOrder()
        this.redoLoading = false
        this.changeTab("library")
      })
    },

    changeTab(tab, param = null, paramContent = null) {
      if (this.$route.query.tab === tab) return

      const params = param && paramContent ? { [param]: paramContent } : null

      this.setSelectedForwardedOrder(null)

      this.$router.push({
        query: { tab: tab },
        params
      })
    },

    async fetchOrder() {
      this.setSelectedOrder(await client.instance.orders.getOrder(this.uid))
    },

    async fetchForwardedOrders() {
      this.setOrderForwardedOrders(
        await client.instance.orders.getForwardedOrders(this.selectedOrder.uid)
      )
    },

    async initialise() {
      this.initialising = true
      this.setOrderFiles(null)
      this.setOrderMessages(null)
      await this.fetchOrder()
      this.initialising = false
    },

    closeOrder() {
      this.setOrderFiles(null)
      this.setOrderMessages(null)
      this.setSelectedOrder(null)
      if (this.$route.params.filter) {
        this.$router.push({
          name: "inboxPageWithFilter",
          params: { filter: this.$route.params.filter }
        })
      } else {
        this.$router.push({
          name: "inboxPage"
        })
      }
    }
  },

  async mounted() {
    if (!this.uid && !this.selectedOrder) return
    if (!this.selectedOrder) await this.initialise()
    await this.fetchForwardedOrders()
    this.initialising = false
    if (!this.$route.query.tab) {
      this.$router.push({ query: { tab: "overview" } })
    }
    this.tab = this.$route.query.tab
    this.triggerReload("exports")
  }
}
</script>
