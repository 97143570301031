import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import PricingPage from "@/components/PricingPage.vue"
import RegistrationPage from "@/components/RegistrationPage.vue"
import ActivationPage from "@/components/ActivationPage.vue"
import InboxPage from "@/components/InboxPage.vue"
import ContactPage from "@/components/ContactPage.vue"
import ProfilePage from "@/components/ProfilePage.vue"
import SelectTenantPage from "@/components/SelectTenantPage.vue"
import FormGeneratorPage from "@/components/FormGeneratorPage.vue"
import ClientPage from "@/components/ClientPage.vue"
import ScanGaugeKitsPage from "@/components/ScanGaugeKitsPage.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "pricingPage",
    component: PricingPage
  },
  {
    path: "/plan/:planCode",
    name: "pricingPagePlanSelected",
    component: PricingPage,
    props: true
  },
  {
    path: "/cases",
    name: "inboxPage",
    component: InboxPage,
    props: {
      filter: "inbox"
    },
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      title: "Case Inbox",
      icon: "$inbox"
    }
  },
  {
    path: "/cases/:filter",
    name: "inboxPageWithFilter",
    component: InboxPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      title: "Case Inbox",
      icon: "$inbox"
    }
  },
  {
    path: "/case/:uid",
    name: "caseView",
    component: InboxPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      caseView: true
    }
  },
  {
    path: "/activating",
    name: "activationPage",
    component: ActivationPage,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      title: "Activating...",
      icon: "$inbox"
    }
  },
  {
    path: "/contact",
    name: "contactPage",
    component: ContactPage
  },
  {
    path: "/register/:planCode",
    name: "registrationPage",
    component: RegistrationPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: false,
      requiresSelectedTenant: false,
      requiresMultipleTenants: false,
      requiresSubscription: false
    }
  },
  {
    path: "/profile",
    name: "profilePage",
    component: ProfilePage,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      title: "Account Settings",
      icon: "mdi-cog-outline"
    }
  },
  {
    path: "/select-business",
    name: "selectTenantPage",
    component: SelectTenantPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: false,
      requiresSelectedTenant: false,
      requiresMultipleTenants: true,
      requiresSubscription: false
    }
  },
  {
    path: "/form-generator",
    name: "formGeneratorPage",
    component: FormGeneratorPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      title: "Your Order Form",
      icon: "$orderform"
    }
  },
  {
    path: "/clients",
    name: "clientPage",
    component: ClientPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: true,
      title: "Client Directory",
      icon: "mdi-book-account-outline"
    }
  },
  {
    path: "/scan-gauge-kits",
    name: "scanGaugeKitsPage",
    component: ScanGaugeKitsPage,
    props: true,
    meta: {
      requiresLogin: true,
      requiresRegistration: true,
      requiresTenants: true,
      requiresSelectedTenant: true,
      requiresMultipleTenants: false,
      requiresSubscription: false,
      title: "Scan Gauge Kits",
      icon: "$scanGaugeKit"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== "pricingPage" && !store.getters.entryRoute)
    store.commit("SET_ENTRY_ROUTE", to)
  if (to.meta.requiresLogin && !store.getters.loggedIn) {
    return next({
      path: "/",
      replace: true
    })
  }
  if (to.meta.requiresRegistration && !store.getters.registered) {
    return next({
      path: "/",
      replace: true
    })
  }
  if (to.meta.requiresTenants && !store.getters.hasTenants) {
    return next({
      path: "/",
      replace: true
    })
  }
  if (to.meta.requiresSelectedTenant && !store.getters.hasTenant) {
    return next({
      path: "/",
      replace: true
    })
  }
  if (to.meta.requiresMultipleTenants && !store.getters.hasMultipleTenants) {
    return next({
      path: "/",
      replace: true
    })
  }
  if (to.meta.requiresSubscription && !store.getters.activeSubscription) {
    return next({
      path: "/",
      replace: true
    })
  }
  if (!to.params.filter && from.params.filter) {
    to.params.filter = from.params.filter
  }
  next()
})

export default router
