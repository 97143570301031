export function deepEqual(obj1, obj2, exceptions) {
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (exceptions?.includes(key)) continue
    if (!(key in obj2) || !deepEqual(obj1[key], obj2[key], exceptions)) {
      return false
    }
  }

  return true
}

export function deepValid(obj, optionals) {
  if (typeof obj !== "object" || obj === null) {
    return obj !== "" && obj !== null && obj !== undefined
  }

  const keys = Object.keys(obj)
  return keys.every((key) => {
    if (optionals.includes(key)) return true
    if (Array.isArray(obj[key]) && obj[key].length === 0) return false
    return deepValid(obj[key], optionals)
  })
}

export function deepCopy(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  let copy
  if (Array.isArray(obj)) {
    copy = []
    obj.forEach((item, index) => {
      copy[index] = deepCopy(item)
    })
  } else {
    copy = {}
    Object.keys(obj).forEach((key) => {
      copy[key] = deepCopy(obj[key])
    })
  }
  return copy
}

export function debounce(func, delay) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(func, delay, ...args)
  }
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function capitalise(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase()
}

export function toSnakeCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w_]/g, "")
    .replace(/_+/g, "_")
}

export function toDashCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]/g, "")
    .replace(/-+/g, "-")
}

export function toPascalCase(text) {
  return text
    .replace(/[^a-zA-Z0-9\s]/g, " ")
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
}

export function toCamelCase(str) {
  let words = str.split(/[\s_]+/)
  for (let i = 1; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase()
  }
  return words.join("")
}

export function toTitleCase(string) {
  if (!string) return
  return string
    .replaceAll("_", " ")
    .replace(
      /[a-zA-Z]+/g,
      (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    )
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

function getLuminance(r, g, b) {
  // Convert RGB values to sRGB
  r = r / 255.0
  g = g / 255.0
  b = b / 255.0

  // Apply gamma correction
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  // Calculate luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

function getContrastRatio(l1, l2) {
  return (l1 + 0.05) / (l2 + 0.05) > 1
    ? (l1 + 0.05) / (l2 + 0.05)
    : (l2 + 0.05) / (l1 + 0.05)
}

export function getTextColor(bgColor) {
  // Extract RGB components from hex color
  const r = parseInt(bgColor.slice(1, 3), 16)
  const g = parseInt(bgColor.slice(3, 5), 16)
  const b = parseInt(bgColor.slice(5, 7), 16)

  const bgLuminance = getLuminance(r, g, b)

  const blackLuminance = getLuminance(0, 0, 0)
  const whiteLuminance = getLuminance(255, 255, 255)

  const contrastWithWhite = getContrastRatio(bgLuminance, whiteLuminance)

  if (contrastWithWhite > 2.5) return "white"

  const contrastWithBlack = getContrastRatio(bgLuminance, blackLuminance)

  return contrastWithBlack > contrastWithWhite ? "black" : "white"
}

export function adjustShade(hex, factor) {
  // Convert HEX to RGB
  let r = parseInt(hex.slice(1, 3), 16)
  let g = parseInt(hex.slice(3, 5), 16)
  let b = parseInt(hex.slice(5, 7), 16)

  // Adjust each component while preserving the ratio
  r = Math.min(255, Math.max(0, Math.floor(r * factor)))
  g = Math.min(255, Math.max(0, Math.floor(g * factor)))
  b = Math.min(255, Math.max(0, Math.floor(b * factor)))

  // Convert back to HEX
  const toHex = (value) => value.toString(16).padStart(2, "0")
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export const scanBodyLibraries = [
  {
    text: "Atlantis IO FLO-S Scanbody for MultiBase Abutment EV Ø4.8 (IO A04S)",
    value: "IO A04S"
  },
  {
    text: "BioHorizons Multi-Unit Titanium Scan Body Ø4.8 (PXMUTSB)",
    value: "PXMUTSB"
  },
  {
    text: "ZimVie 3i Low Profile GenTek Intraoral Scanbody (Non-Eng) Ø4.8 (ZFX05I-ZB-LP-48-NE)",
    value: "ZFX05I-ZB-LP-48-NE"
  },
  {
    text: "Biotech Kontact Scan body for MUA conical abutment Ø4.9 (KECSCANP)",
    value: "KECSCANP"
  },
  {
    text: "DESS Intraoral Scan Body on Multiunit (RP) Ø4.8 (52.007)",
    value: "52.007"
  },
  { text: "DIO External Conical Abutment New Ø4.8 (N/A)", value: "N/A" },
  {
    text: "Euroteknika Tetra multi-unit abutment Scanbody Ø4.8 (ETK_UN.SBP)",
    value: "ETK_UN.SBP"
  },
  {
    text: "MEDENTiKA MG Series Multi-unit scanbody Ø4.8 (MG 8500)",
    value: "MG 8500"
  },
  {
    text: "Medentis ICX-MULTI Scan cap Ø4.8 (C-030-000010)",
    value: "C-030-000010"
  },
  {
    text: "Megagen MUA Level Scan Abutment Ø4.8 (AMUASR4013)",
    value: "AMUASR4013"
  },
  { text: "MIS Scan post for Multi-Unit (MU-SP102)", value: "MU-SP102" },
  {
    text: "Neodent GM Mini Conical Abutment Scanbody Ø4.8 (108.196)",
    value: "108.196"
  },
  {
    text: "Elos Accurate Scan Body for Nobel Biocare Multi-Unit Abutment Ø4.8 (IO 2C-A)",
    value: "IO 2C-A"
  },
  {
    text: "Osstem Multi-unit scanbody Ø4.8 (TS_Multi Scanbody)",
    value: "TS_Multi Scanbody"
  },
  { text: "Nexus Narrow Scanbody Ø4.8 (SG020103)", value: "SG020103" },
  {
    text: "Southern Implants Compact Conical Ø4.8 Scanning Flag Ø4.8 (SFT-MC-48)",
    value: "SFT-MC-48"
  },
  { text: "Straumann SRA 4.6 Scanbody (25.0081)", value: "25.0081" }
]
