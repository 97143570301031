<template>
  <v-card flat class="rounded-lg mb-2">
    <v-row @click="openOrder()" style="cursor: pointer">
      <v-col xl="2" lg="2" md="6" sm="12">
        <span v-if="order.assigned">
          <v-icon small>$inbox</v-icon>
          {{ order.order_number }}
        </span>
        <span v-if="!order.assigned">
          <v-chip label small color="primary" class="px-1"> Pending </v-chip>
        </span>
        <v-tooltip top class="rounded-20">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              v-show="order.awaiting_response"
              class="rounded-10 mr-1 notification-warning"
            >
              <v-icon small class="">$alarm</v-icon>
            </v-chip>
          </template>
          <span class="rounded-20">Awaiting Approval Response</span>
        </v-tooltip>
        <v-tooltip top class="rounded-10">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              dark
              v-show="order.new_message"
              class="rounded-10 primary"
            >
              <v-icon small class="">mdi-message-outline</v-icon>
            </v-chip>
          </template>
          <span>New Message</span>
        </v-tooltip>
      </v-col>
      <v-col xl="2" lg="2" md="6" sm="12">
        <v-icon color="secondary" small>mdi-office-building-outline</v-icon>
        {{ order.client_name }}
      </v-col>
      <v-col xl="2" lg="2" md="6" sm="12">
        <span v-if="order.assigned">
          <v-icon color="secondary" small>mdi-account-outline</v-icon>
          {{ order.patient_reference }}
        </span>
        <span v-if="!order.assigned">
          {{ order.patient_reference }}
          <v-chip color="primary" small label class="px-1"> Existing </v-chip>
        </span>
      </v-col>
      <v-col xl="3" lg="3" md="6" sm="12">
        <span v-for="(status, index) in order.statuses" :key="index">
          <v-chip
            small
            outlined
            v-if="status === 'inbox'"
            class="rounded-10 mr-1"
          >
            <v-icon small class="mr-2">$inboxreceived</v-icon>
            INBOX
          </v-chip>
          <v-chip
            small
            outlined
            v-if="status === 'exported'"
            class="rounded-10 mr-1"
          >
            <v-icon small class="mr-2">$implantexport</v-icon>
            IMPLANTS EXPORTED
          </v-chip>
          <v-chip
            small
            outlined
            v-if="status === 'design'"
            class="rounded-10 mr-1"
          >
            <v-icon small class="mr-2">$toothdesigns</v-icon>
            DESIGN ONLY
          </v-chip>
          <v-chip
            small
            outlined
            v-if="status === 'bar'"
            class="rounded-10 mr-1"
          >
            <v-icon small class="mr-2">$milledbar</v-icon>
            MILLED BARS
          </v-chip>
        </span>
      </v-col>
      <v-col xl="1" lg="1" md="6" sm="12">
        <v-icon color="secondary" small>mdi-update</v-icon>
        {{ order.sessions.length }}
      </v-col>
      <v-col xl="2" lg="2" md="6" sm="12">
        <v-icon color="secondary" small>mdi-clock-outline</v-icon>
        {{ order.created_at | luxon({ output: { format: "med" } }) }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "OrderCard",

  props: ["order"],

  methods: {
    openOrder() {
      this.$emit("open", this.order)
    }
  }
}
</script>
