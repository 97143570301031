<template>
  <v-dialog max-width="1000" v-model="showDialog" content-class="rounded-20">
    <v-card v-if="value">
      <v-card-title> ASSIGN TO EXISTING ORDER </v-card-title>
      <v-card-text>
        <p>
          <strong>{{ value.client_name }}</strong> has advised that this order
          is for an existing patient.
        </p>
        <p>
          <strong>
            <v-icon small>mdi-account-outline</v-icon>
            PATIENT ID:
          </strong>
          {{ value.patient_reference.toUpperCase() }}
        </p>
        <p>Please select an existing order to associate this submission to.</p>
        <v-autocomplete
          outlined
          dense
          v-model="targetOrder"
          :items="targetItems"
        >
          <template v-slot:selection="{ item }">
            <v-row class="ma-0">
              <v-col cols="2">
                <v-chip label small outlined>
                  {{ item.order_number }}
                </v-chip>
              </v-col>
              <v-col cols="3">
                <v-icon small>mdi-office-building-outline</v-icon>
                {{ item.client_name }}
              </v-col>
              <v-col cols="3">
                <v-icon small>mdi-account-outline</v-icon>
                {{ item.patient_reference }}
              </v-col>
              <v-col cols="3">
                <v-icon small>mdi-clock-outline</v-icon>
                <small>
                  {{ item.submitted_at | luxon }}
                </small>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" title="">
              <v-row class="ma-0">
                <v-col cols="2">
                  <v-chip label small outlined>
                    {{ item.order_number }}
                  </v-chip>
                </v-col>
                <v-col cols="3">
                  <v-icon small>mdi-office-building-outline</v-icon>
                  {{ item.client_name }}
                </v-col>
                <v-col cols="3">
                  <v-icon small>mdi-account-outline</v-icon>
                  {{ item.patient_reference }}
                </v-col>
                <v-col cols="3">
                  <v-icon small>mdi-clock-outline</v-icon>
                  <small>
                    {{ item.submitted_at | luxon }}
                  </small>
                </v-col>
              </v-row>
            </v-list-item>
            <v-divider />
          </template>
        </v-autocomplete>
        <p>
          Alternativly, if you cannot find an existing patient order, please
          click <strong>Save as New Order</strong> below to create a new order
          for this patient.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="btn-primary"
          @click="assignToSelf"
          :disabled="loading"
          :loading="loading"
          >Save as New Order</v-btn
        >
        <v-spacer />
        <v-btn class="btn-error" @click="showDialog = false" :disabled="loading"
          >Cancel</v-btn
        >
        <v-btn
          class="btn-success"
          @click="assignToOrder(targetOrder)"
          :disabled="loading || !targetOrder"
          :loading="loading"
        >
          Assign to Existing Order
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "MergeDialog",

  props: ["value"],

  data() {
    return {
      targetOrder: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters(["inboxItems", "currentPlan"]),

    targetItems() {
      return this.inboxItems
        .filter(
          (item) =>
            item.uid !== this.value.uid &&
            item.assigned &&
            item.sessions.length < this.currentPlan.order_session_limit
        )
        .map((item) => {
          return {
            text: `${item.order_number} ${item.client_name} ${item.patient_reference}`,
            value: item,
            order_number: item.order_number,
            client_name: item.client_name,
            patient_reference: item.patient_reference,
            submitted_at: item.submitted_at
          }
        })
    },

    showDialog: {
      get() {
        return !!this.value
      },

      set(value) {
        if (value) return
        this.$emit("input", false)
      }
    }
  },

  methods: {
    ...mapActions(["setSelectedOrder", "triggerReload"]),

    async assignToOrder(order) {
      this.loading = true
      await client.instance.orders.assignOrder(this.value.uid, order.uid)
      this.triggerReload("all")
      this.$router.push({ name: "caseView", params: { uid: order.uid } })
      this.loading = false
      this.showDialog = false
    },

    async assignToSelf() {
      this.loading = true
      await client.instance.orders.assignOrder(this.value.uid, this.value.uid)
      this.triggerReload("all")
      this.$router.push({ name: "caseView", params: { uid: this.value.uid } })
      this.showDialog = false
      this.loading = false
    }
  }
}
</script>
