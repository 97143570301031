import { axiosClient } from "@/plugins/axios"

class Settings {
  async fetchOrderingBaseURI() {
    const response = await axiosClient.get("/tenants/ordering_uri")
    return response.data
  }
}

class Instances {
  async fetchInstances() {
    const response = await axiosClient.get("/instances/")
    return response.data
  }
}

class Users {
  async fetchID() {
    try {
      const response = await axiosClient.get("/users/id_strings")
      return response.data
    } catch (error) {
      return false
    }
  }

  async fetchMe() {
    try {
      const response = await axiosClient.get("/users/me")
      return response.data
    } catch (error) {
      return false
    }
  }

  async signUp(form) {
    const response = await axiosClient.post("/users/sign_up", form)
    return response.data
  }

  async addIdString(form) {
    const response = await axiosClient.post("/users/me/id_strings", form)
    return response.data
  }

  async removeIdString(idString) {
    const response = await axiosClient.delete(`/users/id_strings/${idString}`)
    return response.data
  }
}

class Tenants {
  constructor() {
    this.tenantUid = null
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchUserTenants() {
    const response = await axiosClient.get("/tenants/")
    return response.data
  }

  async addTenants(form) {
    const response = await axiosClient.post("/tenants/", form)
    return response.data
  }

  async fetchCountries() {
    const response = await axiosClient.get("/tenants/countries")
    return response.data
  }

  async checkSlug(slug) {
    const response = await axiosClient.get(`/tenants/slug/${slug}`)
    return response.data
  }

  async fetchTenantInformation() {
    const response = await axiosClient.get(`/tenants/${this.tenantUid}`)
    return response.data
  }

  async updateTenantInformation(payload) {
    const response = await axiosClient.put(
      `/tenants/${this.tenantUid}`,
      payload
    )
    return response.data
  }

  async deleteTenant() {
    const response = await axiosClient.delete(`/tenants/${this.tenantUid}`)
    return response.data
  }

  async modifyTenant(form) {
    const response = await axiosClient.put(`/tenants/${this.tenantUid}`, form)
    return response.data
  }

  async fetchTenantRoles() {
    const response = await axiosClient.get(`/tenants/${this.tenantUid}/roles`)
    return response.data
  }

  async addRole(form) {
    const response = await axiosClient.post(
      `/tenants/${this.tenantUid}/roles`,
      form
    )
    return response.data
  }

  async modifyRole(role, userUid) {
    const response = await axiosClient.put(
      `/tenants/${this.tenantUid}/roles/${userUid}`,
      role
    )
    return response.data
  }
  async toggleNotify(userUid) {
    const response = await axiosClient.post(
      `/tenants/${this.tenantUid}/roles/${userUid}/notify`
    )
    return response.data
  }
  async deleteRole(userUid) {
    const response = await axiosClient.delete(
      `/tenants/${this.tenantUid}/roles/${userUid}`
    )
    return response.data
  }
}

class Billing {
  constructor() {
    this.tenantUid = null
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  // Subscriptions

  async subscribe(form) {
    const response = await axiosClient.post("/billing/subscriptions", form)
    return response.data
  }

  async deletePendingSubscriptions() {
    const response = await axiosClient.delete(
      `/billing/${this.tenantUid}/subscriptions/pending`
    )
    return response.data
  }

  async subscriptionInformation() {
    try {
      const response = await axiosClient.get(
        `/billing/${this.tenantUid}/subscriptions`
      )
      return response.data
    } catch (error) {
      return
    }
  }

  async subscriptionSchedule() {
    try {
      const response = await axiosClient.get(
        `/billing/${this.tenantUid}/subscriptions/schedule`
      )
      return response.data
    } catch (error) {
      return
    }
  }

  async toggleAutoRenew() {
    const response = await axiosClient.post(
      `/billing/${this.tenantUid}/subscriptions/cancel`
    )
    return response.data
  }

  async releaseSchedule() {
    const response = await axiosClient.delete(
      `/billing/${this.tenantUid}/subscriptions/schedule`
    )
    return response.data
  }

  async changeSubscription(form) {
    const response = await axiosClient.post(
      `/billing/${this.tenantUid}/subscriptions/change`,
      form
    )
    return response.data
  }

  // Payment Methods

  async fetchPaymentMethods() {
    try {
      const response = await axiosClient.get(
        `/billing/${this.tenantUid}/payment_methods`
      )
      return response.data
    } catch {
      return []
    }
  }

  async addPaymentMethod(cardToken) {
    const response = await axiosClient.post(
      `/billing/${this.tenantUid}/payment_methods/${cardToken}`
    )
    return response.data
  }

  async setDefaultPaymentMethod(paymentMethod) {
    const response = await axiosClient.put(
      `/billing/${this.tenantUid}/payment_methods/${paymentMethod.payment_id}`
    )
    return response.data
  }

  async removePaymentMethod(paymentMethod) {
    const response = await axiosClient.delete(
      `/billing/${this.tenantUid}/payment_methods/${paymentMethod.payment_id}`
    )
    return response.data
  }

  // Invoices

  async fetchInvoices() {
    try {
      const response = await axiosClient.get(
        `billing/${this.tenantUid}/invoice`
      )
      return response.data
    } catch {
      return []
    }
  }

  async checkInvoice(invoice_uid) {
    const response = await axiosClient.get(
      `/billing/${this.tenantUid}/invoice/${invoice_uid}`
    )
    return response.data
  }

  // Quotas

  async fetchQuotas() {
    const response = await axiosClient.get(`/billing/${this.tenantUid}/quota`)
    return response.data
  }

  // Purchases

  async checkStrategy(form) {
    const response = await axiosClient.post(
      `/billing/${this.tenantUid}/purchase/check`,
      form
    )
    return response.data
  }

  async commitPurchase(payload) {
    const response = await axiosClient.post(
      `/billing/${this.tenantUid}/purchase/commit`,
      payload
    )
    return response.data
  }

  // Tokens

  async checkToken(tokenUid) {
    const response = await axiosClient.get(
      `/billing/${this.tenantUid}/token/${tokenUid}`
    )
    return response.data
  }

  async consumeToken(tokenUid, form) {
    const response = await axiosClient.post(
      `/billing/${this.tenantUid}/token/${tokenUid}`,
      form
    )
    return response.data
  }

  async attemptExportDownload(orderUid, exportForm) {
    try {
      await axiosClient.post(
        `/billing/${this.tenantUid}/order/${orderUid}/export`,
        exportForm
      )
      return true
    } catch {
      return false
    }
  }
}

class GaugeKits {
  constructor() {
    this.tenantUid = null
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchScanGaugeKits() {
    const response = await axiosClient.get(`/gauge_kits/${this.tenantUid}`)
    return response.data
  }

  async fetchGaugeKitSalesOrders() {
    const response = await axiosClient.get(
      `/gauge_kits/sales_orders/${this.tenantUid}`
    )
    return response.data
  }

  async orderScanGaugeKit(paymentMethodId) {
    const response = await axiosClient.post(`/gauge_kits/${this.tenantUid}`, {
      payment_id: paymentMethodId
    })
    return response.data
  }
}

class ImplantPlatforms {
  constructor() {
    this.tenantUid = null
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchImplantPlatforms() {
    const response = await axiosClient.get(
      `/implant_platforms/${this.tenantUid}`
    )
    return response.data
  }
}

class Plans {
  async fetchAllPlans(country) {
    const response = await axiosClient.get(`/plans/${country}`)
    return response.data
  }
}

class Products {
  async fetchAllProducts(planCode) {
    const response = await axiosClient.get(`/products/${planCode}`)
    return response.data
  }
}

class Components {
  async fetchExportComponents() {
    const response = await axiosClient.get("/components/")
    return response.data
  }

  async fetchExportMesh(componentId) {
    const response = await axiosClient.get(`/components/${componentId}`)
    return response.data
  }
}

export default class GatewayAPI {
  constructor() {
    this.settings = new Settings()
    this.instances = new Instances()
    this.users = new Users()
    this.tenants = new Tenants()
    this.billing = new Billing()
    this.gaugeKits = new GaugeKits()
    this.plans = new Plans()
    this.products = new Products()
    this.implantPlatforms = new ImplantPlatforms()
    this.components = new Components()
  }

  setTenantUid(uid) {
    this.tenants.setTenantUid(uid)
    this.billing.setTenantUid(uid)
    this.gaugeKits.setTenantUid(uid)
    this.implantPlatforms.setTenantUid(uid)
  }
}
