<template>
  <v-progress-circular
    width="6"
    :size="sizes[0]"
    :rotate="rotates[0]"
    :color="adjustShade($vuetify.theme.themes.dark.primary, 1.2)"
    indeterminate
    class="ma-2"
  >
    <v-progress-circular
      width="5"
      :size="sizes[1]"
      :rotate="rotates[1]"
      color="primary"
      indeterminate
    >
      <v-progress-circular
        width="4"
        :size="sizes[2]"
        :rotate="rotates[2]"
        :color="adjustShade($vuetify.theme.themes.dark.primary, 0.8)"
        indeterminate
      >
        <v-img
          contain
          width="100"
          :src="require('@/assets/nexus-connect-logo.svg')"
        />
      </v-progress-circular>
    </v-progress-circular>
  </v-progress-circular>
</template>

<script>
import { adjustShade } from "@/utils"

export default {
  name: "LoadingSpinner",

  data() {
    return {
      number: 5,
      sizes: ["160", "140", "120"],
      rotates: ["135", "90", "45"],
      adjustShade
    }
  }
}
</script>
