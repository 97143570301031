<template>
  <v-card flat>
    <v-card-title>
      <v-icon class="mr-2"> $surgicalTool </v-icon>
      Immediate Workflow
    </v-card-title>
    <v-card-text>
      <v-row align="center" class="text-subtitle-2 px-5">
        <v-icon class="mr-2"></v-icon>
        <span class="success--text" v-if="immediateWorkflow">
          Immediate Workflow Enabled
        </span>
        <span class="error--text" v-if="!immediateWorkflow">
          Immediate Workflow Disabled
        </span>
        <v-spacer />
        <v-switch color="success" v-model="immediateWorkflow" inset />
      </v-row>
      <v-row class="px-5 mt-2">
        <div>
          Enabling this option will allow your lab to support surgical immedate
          procedures.
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ImmediateWorkflowSelector",

  props: ["value"],

  computed: {
    immediateWorkflow: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
