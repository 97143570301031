<template>
  <v-container fluid fill-height class="d-flex align-start py-0">
    <v-row class="fill-height">
      <v-col xl="6" lg="6" md="12" sm="12" class="py-0">
        <ProductForm
          v-model="questions"
          :originals="originalQuestions"
          :initialising="initialising"
          @save="handleSave"
        />
      </v-col>
      <v-col xl="6" lg="6" md="12" sm="12" class="py-0">
        <FormPreview :questions="questions" :initialising="initialising" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductForm from "./FormGenerator/ProductForm"
import FormPreview from "./FormGenerator/FormPreview"
import { deepCopy, generateUUID } from "@/utils"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "FormGeneratorPage",
  components: {
    ProductForm,
    FormPreview
  },

  data() {
    return {
      initialising: true,
      loading: false,
      questions: [],
      originalQuestions: []
    }
  },

  watch: {
    orderFormQuestions() {
      if (!this.questions.length) this.initialise()
    },
    questions: {
      handler(value) {
        value.forEach((q, index) => {
          q.order = index + 1
        })
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters(["orderFormQuestions"])
  },

  methods: {
    ...mapActions(["triggerReload"]),

    handleSave(saveResponse) {
      this.originalQuestions = deepCopy(saveResponse)
      this.triggerReload("questions")
    },

    initialise() {
      this.questions = deepCopy(this.orderFormQuestions)
      this.questions.forEach((q) => {
        q.id = generateUUID()
        this.$set(q, "expanded", null)
      })
      this.originalQuestions = deepCopy(this.questions)
      this.initialising = false
    }
  },

  mounted() {
    if (this.orderFormQuestions?.length) this.initialise()
    if (Array.isArray(this.orderFormQuestions)) this.initialising = false
  }
}
</script>

<!--
  TODO:

  Leaving the page with unsaved changes is too easy.
  Display confirmation modal to confirm that unsaved changes will be lost
 -->
