<template>
  <v-hover v-slot:default="{ hover }">
    <div>
      <v-card
        flat
        height="175"
        width="175"
        :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
        @click="$emit('chooseOrder', 'DESIGN')"
      >
        <v-card-text
          class="px-1 text-center text-subtitle-1 pb-0"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          <strong> TOOTH DESIGN ONLY </strong>
        </v-card-text>
        <v-container fluid>
          <v-img
            v-if="!hover"
            contain
            :src="require('@/assets/design-only-prosthesis.png')"
            position="center center"
          />
          <v-img
            v-if="hover"
            contain
            :src="require('@/assets/design-only-prosthesis-white.png')"
            position="center center"
          />
        </v-container>
      </v-card>
      <v-card flat v-if="hover" width="250px">
        <v-card-text class="text-left">
          <ul>
            <li>Full-arch bridge design.</li>
            <li>Direct to MUA or Ti Base.</li>
            <li>Milled or printed by you.</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "DesignOptions"
}
</script>
