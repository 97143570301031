<template>
  <v-card flat class="mx-4 py-2 rounded-10">
    <v-row v-if="loading && !questions.length" class="ma-5 text-center">
      <v-col>
        <LoadingSpinner />
      </v-col>
    </v-row>
    <v-row v-if="!loading && !questions.length" class="ma-5">
      No Questions
    </v-row>

    <v-list class="py-0">
      <v-list-item-group>
        <draggable
          v-model="questions"
          draggable=".draggable"
          style="width: 100%"
          :disabled="loading"
        >
          <v-list-item
            v-for="(question, index) in questions"
            :class="['px-0', question.expanded === 0 ? null : 'draggable']"
            :key="`questions-${question.id}`"
          >
            <v-expansion-panels flat accordion tile v-model="question.expanded">
              <v-expansion-panel flat>
                <v-expansion-panel-header flat>
                  <v-row>
                    <v-col cols="1">
                      <v-avatar
                        :color="determineNumberColor(question)"
                        size="32"
                      >
                        <span class="white--text">
                          {{ question.order }}
                        </span>
                      </v-avatar>
                    </v-col>

                    <v-col align-self="center">
                      <strong>{{ question.name || "New question" }}</strong>
                    </v-col>

                    <v-col cols="1" align-self="center">
                      <v-btn
                        small
                        icon
                        @click.stop="removeQuestion(index)"
                        :disabled="loading"
                      >
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <QuestionForm
                    v-model="questions[index]"
                    :validate="validate"
                  />
                </v-expansion-panel-content>
                <v-divider v-if="index < questions.length - 1" />
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </draggable>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import draggable from "vuedraggable"
import QuestionForm from "./QuestionList/QuestionForm"
import { deepValid } from "@/utils"

export default {
  props: ["value", "loading", "validate"],

  components: { draggable, QuestionForm },

  computed: {
    questions: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    removeQuestion(index) {
      this.questions.splice(index, 1)
    },

    determineNumberColor(question) {
      if (this.validate && !deepValid(question, ["description", "expanded"]))
        return "error"
      return question.uid ? "primary" : "accent"
    }
  }
}
</script>
