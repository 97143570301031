<template>
  <v-toolbar class="pl-1 transparent" flat>
    <v-icon large class="mr-2">{{ pageIcon }}</v-icon>
    <v-toolbar-title class="mr-2 title-font">
      {{ pageTitle?.toUpperCase() }}
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-title class="mr-2 title-font">
      {{ currentTenant?.name.toUpperCase() }}
    </v-toolbar-title>
    <v-chip
      v-if="activeSubscription"
      class="rounded-10 px-0"
      large
      outlined
      @click="quotaOverview = true"
    >
      <div class="mx-2">
        <strong class="mr-1 title-font" v-if="currentQuotas">
          {{ getQuota("exports") }}
        </strong>
        <span v-if="!currentQuotas" class="mr-2">
          <v-progress-circular indeterminate size="12" width="2" />
        </span>
        <v-icon large>$implantexport</v-icon>
      </div>
      <v-divider vertical inset />
      <div class="mx-2">
        <strong class="mr-1 title-font" v-if="currentQuotas">
          {{ getQuota("designs") }}
        </strong>
        <span v-if="!currentQuotas" class="mr-2">
          <v-progress-circular indeterminate size="12" width="2" />
        </span>
        <v-icon large>$toothdesigns</v-icon>
      </div>
      <v-divider vertical inset />
      <div class="mx-2">
        <strong class="mr-1 title-font" v-if="currentQuotas">
          {{ getQuota("bars") }}
        </strong>
        <span v-if="!currentQuotas" class="mr-2">
          <v-progress-circular indeterminate size="12" width="2" />
        </span>
        <v-icon large>$milledbar</v-icon>
      </div>
    </v-chip>
    <v-dialog v-model="quotaOverview" max-width="800px">
      <v-card class="rounded-10 pb-4">
        <v-card-title>
          <h2 class="title-h2 primary--text">Overview</h2>
        </v-card-title>
        <QuotaOverview class="px-2 pb-2" />
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex"
import QuotaOverview from "@/components/shared/QuotaOverview.vue"

export default {
  name: "ToolBar",

  components: { QuotaOverview },

  data() {
    return {
      quotaOverview: false
    }
  },

  computed: {
    ...mapGetters(["currentTenant", "currentQuotas", "activeSubscription"]),
    pageIcon() {
      return this.$route.meta.icon
    },

    pageTitle() {
      return this.$route.meta.title
    }
  },

  methods: {
    getQuota(quotaCode) {
      return this.currentQuotas?.find((q) => q.quota_code === quotaCode)
        ?.current
    }
  }
}
</script>
