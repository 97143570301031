<template>
  <v-card flat>
    <v-card-title class="title-font"> ORDER SESSION </v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col cols="4">
          <v-card flat class="rounded-pill rounded-r-0">
            <div class="primary">
              <SessionSwitcher class="ml-5" :persistTab="true" />
            </div>
          </v-card>
        </v-col>
        <v-col class="black--text text-center">
          Ensure that you have selected the correct Order Session
        </v-col>
        <v-col cols="1">
          <v-card flat class="rounded-pill rounded-r-0 d-flex">
            <v-btn
              class="elevation-0 primary rounded-pill rounded-l-0 flex-grow-1"
              min-width="32"
              disabled
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SessionSwitcher from "@/components/InboxPage/OrderView/SessionSwitcher.vue"
export default {
  name: "FoSessionOption",

  components: {
    SessionSwitcher
  }
}
</script>
