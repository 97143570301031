<template>
  <v-app-bar app clipped-left color="white" flat>
    <v-spacer />
    <v-toolbar-title
      @click="navigateHome()"
      style="position: absolute; transform: translateX(-50%); left: 50%"
    >
      <v-img
        @click="navigateHome"
        contain
        max-width="400"
        style="cursor: pointer"
        :src="require('@/assets/nexus-connect-logo.svg')"
      />
    </v-toolbar-title>
    <v-spacer />
    <v-btn class="btn-primary" @click="logIn()" v-if="!loggedIn">
      <v-icon class="mr-2">mdi-login</v-icon>
      Log in
    </v-btn>
    <v-btn
      elevation="0"
      class="rounded-10 mr-2 btn-primary"
      v-if="
        loggedIn &&
        hasTenant &&
        activeSubscription &&
        features.ordering_platform
      "
      @click="openOrdering()"
    >
      <v-icon class="mr-2">mdi-open-in-new</v-icon>
      <span v-if="$vuetify.breakpoint.mdAndUp">Ordering</span>
    </v-btn>
    <v-btn
      elevation="0"
      class="rounded-10 mr-2"
      @click="changeTenant()"
      v-if="loggedIn && hasMultipleTenants"
    >
      <v-icon class="mr-2">mdi-swap-horizontal</v-icon>
      <span v-if="$vuetify.breakpoint.mdAndUp">Switch Business</span>
    </v-btn>
    <v-btn
      elevation="0"
      class="rounded-10 btn-error"
      @click="logOut()"
      v-if="loggedIn"
    >
      <v-icon class="mr-2">mdi-logout</v-icon>
      <span v-if="$vuetify.breakpoint.mdAndUp">Log out</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { login, logout } from "@/lib/authentication"

export default {
  name: "AppBar",

  computed: {
    ...mapGetters([
      "loading",
      "loggedIn",
      "hasTenants",
      "hasTenant",
      "hasMultipleTenants",
      "currentTenant",
      "activeSubscription",
      "orderingBaseURI",
      "features"
    ])
  },

  methods: {
    ...mapActions(["setCurrentTenant", "setSubscription", "resetData"]),

    navigateHome() {
      this.$router.push("/").catch(() => {})
    },

    openOrdering() {
      window.open(`${this.orderingBaseURI}${this.currentTenant.slug}`)
    },

    changeTenant() {
      this.setSubscription(null)
      this.setCurrentTenant(null)
      this.resetData()
      if (this.$router.currentRoute.name !== "selectTenantPage") {
        this.$router.push({ name: "selectTenantPage" })
      }
    }
  },

  created() {
    this.logIn = login
    this.logOut = logout
  }
}
</script>
