<template>
  <v-card flat class="rounded-20">
    <v-card-title class="title-font pb-0"> ORDER DETAILS </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content two-line>
            <v-list-item-title>
              <v-icon small>mdi-inbox-outline</v-icon>
              STATUSES
            </v-list-item-title>
            <v-list-item-subtitle>
              <StatusComponent class="mt-2" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon small>mdi-office-building-outline</v-icon>
              CLIENT
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text mt-1">
              {{ selectedOrder.client_name.toUpperCase() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon small>mdi-account-outline</v-icon>
              PATIENT ID
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.patient_reference.toUpperCase() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon small>mdi-inbox-outline</v-icon>
              ORDER NUMBER
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.order_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="selectedOrder.immediate">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon small>mdi-tag-outline</v-icon>
              CASE TYPE
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.immediate ? "IMMEDIATE" : "FINAL" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon small>mdi-clock-outline</v-icon>
              DATE SUBMITTED
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.created_at | luxon({ output: "date_short" }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import StatusComponent from "./StatusComponent.vue"

export default {
  name: "PreviewComponent",

  components: {
    StatusComponent
  },

  computed: {
    ...mapGetters(["selectedOrder"])
  }
}
</script>
